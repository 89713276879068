<template>
  <BaseContainer>
    <PlanSettings v-if="usesPlan" />
    <PlanDemoSettings v-else-if="usesContactForDemo" />
  </BaseContainer>
</template>

<script>
import PlanSettings from '@components/Settings/PlanSettings'
import PlanDemoSettings from '@components/Settings/PlanDemoSettings'

export default {
  page: {
    title: 'Plan'
  },

  components: {
    PlanSettings,
    PlanDemoSettings
  },

  computed: {
    /**
     * @return {Boolean}
     */
    usesPlan() {
      return this.$usesFeature('plans')
    },

    /**
     * @return {Boolean}
     */
    usesContactForDemo() {
      return this.$usesFeature('contact-for-demo')
    }
  }
}
</script>
