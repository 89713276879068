<!--
  This currently is only designed for Picked, so it’s hard-coded to
  Picked-language as it’ll probably be replace with something better, maybe
  with specific account managers per-accounts or whatever, but for now...
  @TODO 2020-11-17 Replace content with satellite-agonostic languages
-->
<template>
  <div>
    <SettingsNavigation />

    <div class="max-w-screen-xl mx-auto px-6">
      <BaseCard
        class="text-center"
      >
        <div class="my-4">
          <p>We’d love to show you more about Picked</p>
          <p class="text-lg font-medium">
            Book a demo with your account manager to get started
          </p>
        </div>
        <div class="my-4">
          <img
            src="~@assets/images/guy.jpg"
            class="w-48 mx-auto border p-2 m-4"
            alt=""
          >
          <p>
            <span class="text-lg font-medium">Guy Thornton</span>
            <br>
            <span>Client Success Manager</span>
          </p>
          <p>
            <a
              href="mailto:guy@picked.ai"
              class="text-secondary font-medium"
            >
              guy@picked.ai
            </a>
          </p>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'

export default {
  components: {
    SettingsNavigation
  }
}
</script>
