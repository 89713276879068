<template>
  <div>
    <BaseCard>
      <header class="md:flex md:items-center md:justify-between mb-8">
        <div class="flex items-center">
          <span
            class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
          >
            <Icon
              width="24px"
              height="24px"
              view-box="0 0 24 24"
            >
              <Receipt />
            </Icon>
          </span>
          <h2 class="text-lg">
            Plans
          </h2>
        </div>

        <div v-if="cardState === cardStates.READY">
          <div v-if="showPlansIntervalToggle">
            <div class="text-sm flex items-center lg:justify-end rounded-tl-lg rounded-tr-lg sm:mx-2 my-4">
              <p class="mr-4">
                Pay Annually
                <span class="text-xs bg-gray-600 rounded text-white px-1">Save 20%</span>
              </p>

              <ToggleSwitch
                :is-checked="interval === 'month'"
                @toggle="setPlanInterval()"
              />

              <span class="ml-1">Pay Monthly</span>
            </div>
          </div>
        </div>
      </header>

      <div v-if="cardState === cardStates.READY">
        <div class="w-full grid md:grid-cols-3 gap-3 mb-4">
          <div
            v-for="product in products"
            :key="product.stripeId"
            class="flex flex-col border rounded-lg px-6 py-4"
          >
            <p class="pb-2">
              <span class="text-white rounded p-1 text-sm bg-secondary hover:bg-gray-primary-400 text-white">{{ product.name }}</span>
            </p>
            <div
              v-for="plan in intervalPlans(product.plans)"
              :key="plan.stripeId"
            >
              <div>
                <p class="text-3xl leading-none font-bold mt-3">
                  <span
                    v-if="subscriptionToPlan(plan) && subscriptionToPlan(plan).amount !== plan.amount"
                  >
                    {{ formatted(subscriptionToPlan(plan).amount, subscriptionToPlan(plan).currency) }}
                    <del class="line-through text-gray-500 text-base font-normal">{{ formatted(plan.amount, plan.currency) }}</del>
                    <span class="text-gray-500 text-base font-normal">/{{ plan.interval }}</span>
                  </span>
                  <span
                    v-else-if="plan.recurringInterval === 'year'"
                  >
                    {{ formatted(plan.amount / 12, plan.currency) }} <span class="text-gray-600 text-lg font-normal">/month</span>
                  </span>
                  <span
                    v-else-if="plan.recurringInterval === 'month'"
                  >
                    {{ formatted(plan.amount, plan.currency) }} <span class="text-gray-600 text-lg font-normal">/month</span>
                  </span>
                </p>
                <p class="leading-none text-gray-600 mt-3">
                  <span
                    v-if="plan.recurringInterval === 'year'"
                  >
                    Billed at {{ formatted(plan.amount, plan.currency) }}/{{ plan.recurringInterval }}
                  </span>
                  <span
                    v-if="productsTaxPercentage"
                    class="text-xs text-gray-500 font-bold tracking-wide"
                  >
                    +{{ productsTaxPercentage }}% VAT
                  </span>
                </p>

                <ProductMarketing
                  :product="product"
                  class="mb-6 mt-4 text-gray-600"
                >
                </ProductMarketing>
              </div>

              <div v-if="subscriptionToPlan(plan)">
                <span
                  v-if="subscriptionToPlan(plan).cancelAt"
                  class="block w-full text-xs text-center uppercase p-3 border rounded tracking-wider"
                >
                  Cancelling
                </span>
                <span
                  v-else
                  class="block w-full text-xs text-center uppercase p-3 border rounded tracking-wider"
                >
                  Current
                </span>
              </div>
              <div v-else-if="pendingSubscriptionToPlan(plan)">
                <span class="block w-full text-xs text-center uppercase p-3 border rounded tracking-wider">Pending</span>
              </div>
              <BaseButton
                v-else-if="canceledPlan(plan)"
                class="text-center mt-auto"
                :loading="reactivationLoading"
                @click="reactivatePlan(plan)"
              >
                Reactivate
              </BaseButton>
              <BaseButton
                v-else-if="!canReactivateSubscription && !hasPendingSubscription"
                class="text-center mt-auto bg-secondary hover:bg-gray-primary-400 text-white w-full"
                :to="confirmPlanLink(plan.stripeId)"
              >
                Select
              </BaseButton>
            </div>
          </div>
        </div>

        <div class="my-8 bg-white rounded-lg p-6 relative z-40 border text-gray-700 sm:text-center">
          <ul class="text-secondary md:flex items-center justify-center space-y-2 lg:space-y-0 space-x-8">
            <li
              v-for="(feature, index) in plansFeatures['all-plans']"
              :key="index"
              class="flex items-center"
            >
              <Icon
                view-box="0 0 24 24"
                class="w-6 h-6 text-gray-600"
              >
                <Check />
              </Icon>
              <span class="ml-2 -mt-1 text-gray-700">
                {{ feature }}
              </span>
            </li>
          </ul>
        </div>

        <div
          v-for="subscription in inactiveSubscriptions"
          :key="`subscription-${subscription.id}`"
        >
          <Notice
            v-if="subscription.state === 'canceled'"
            class="mt-12 mb-6"
            variant="info"
          >
            Your plan ends on {{ formatDate(parseISO(subscription.currentTermEndsAt)) }}
          </Notice>
        </div>

        <div
          v-for="subscription in activeSubscriptions"
          :key="`subscription-${subscription.id}`"
        >
          <div v-if="subscription.schedule.length > 0">
            <Notice
              v-for="(schedule, i) in subscription.schedule"
              :key="i"
              variant="info"
            >
              Your plan will be downgraded on {{ formatDate(parseISO(schedule.startDate)) }}
            </Notice>
            <div
              v-if="!cancelDowngradeLoading"
              class="flex items-center justify-between"
            >
              <a
                href="javascript:;"
                class="focus:outline-none text-red-500 underline hover:text-secondary duration-150"
                @click="cancelDowngrade(subscription)"
              >
                Cancel downgrade
              </a>
            </div>
            <Loader
              v-else
            />
          </div>

          <div v-else>
            <div
              v-if="!cancelDowngradeLoading"
            >
              <a
                v-if="!subscription.cancelAt"
                href="javascript:;"
                class="focus:outline-none text-red-500 underline hover:text-secondary duration-150"
                @click="openCancelPlanModal(subscription)"
              >
                Cancel your plan
              </a>
              <span
                v-else
              >
                Your plan ends on {{ formatDate(parseISO(subscription.cancelAt)) }}
              </span>
            </div>
            <Loader
              v-else
            />
          </div>
        </div>
      </div>

      <div
        v-else-if="cardState === cardStates.LOADING"
        class="my-8"
      >
        <Loader />
      </div>

      <div
        v-else-if="cardState === cardStates.ERROR"
        class="my-8"
      >
        Sorry, an error occured
      </div>
    </BaseCard>

    <Modal
      v-if="cancelingSubscription"
      :open="showCancelModal"
      @close="showCancelModal = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Cancel plan
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="showCancelModal = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <div
        v-if="cancelingState === cancelingStates.ready"
        class="p-6"
      >
        <p class="my-3">
          If you cancel now, your plan will continue until {{ formatDate(parseISO(cancelingSubscription.currentPeriodEnd)) }}.
        </p>
        <p class="my-3">
          Once your plan has ended, all new attempts will be locked.
        </p>
        <BaseButton
          variant="danger"
          class="mt-6"
          @click="cancelAtPeriodEnd(cancelingSubscription)"
        >
          I’m sure I want to cancel
        </BaseButton>
      </div>
      <div
        v-else-if="cancelingState === cancelingStates.loading"
        class="m-6"
      >
        <Loader class="py-6" />
      </div>
      <div
        v-else-if="cancelingState === cancelingStates.canceled"
        class="m-6"
      >
        <p class="my-3">
          Your subscription has been canceled and won’t be renewed.
        </p>
        <p class="my-3">
          You can continue to use your remaining subscription until {{ formatDate(parseISO(cancelingSubscription.currentPeriodEnd)) }}.
        </p>
      </div>
      <div
        v-else-if="cancelingState === cancelingStates.terminated"
        class="m-6"
      >
        <p class="my-3">
          Your subscription has been cancelled.
        </p>
      </div>
    </Modal>

    <!-- Canceled downgrade confirm -->
    <Modal
      :open="showCanceledDowngradeModal"
      @close="showCanceledDowngradeModal = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Canceled downgrade
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="showCanceledDowngradeModal = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>
      <div class="m-6">
        <p class="my-3">
          Your downgrade has been canceled. Your plan continues as normal.
        </p>
      </div>
    </Modal>

    <!-- Reactivated confirm -->
    <Modal
      :open="showReactivatedConfirm"
      @close="showReactivatedConfirm = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Reactivated your plan
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="showReactivatedConfirm = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>
      <div class="m-6">
        <p class="my-3">
          Your plan has been reactivated.
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
import Check from '@components/Icons/Check'
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import Notice from '@components/Notice'
import Plus from '@components/Icons/Plus'
import ProductMarketing from '@components/Plans/ProductMarketing'
import Receipt from '@components/Icons/Receipt'
import ToggleSwitch from '@components/FormElements/ToggleSwitch'

import plansFeatures from '@data/plansFeatures'

import { parseISO, isFuture } from 'date-fns'
import { mapGetters } from 'vuex'
import { formatCurrencyWhole } from '@utils/currency'
import { numberFormat } from '@utils/numberFormat'
import { formatDate } from '@utils/formatDate'
import countries from '@groupResources/lists/countries'

const cancelingStates = {
  ready: 'ready',
  loading: 'loading',
  canceled: 'canceled',
  terminated: 'terminated'
}

const cardStates = {
  LOADING: 'loading',
  ERROR: 'error',
  READY: 'ready'
}

export default {
  components: {
    Check,
    Icon,
    Loader,
    Modal,
    Notice,
    Plus,
    ProductMarketing,
    Receipt,
    ToggleSwitch
  },

  data() {
    return {
      cardStates,
      cardState: cardStates.LOADING,

      countries,

      countryCode: null,

      cancelingStates,
      formatDate,
      formatCurrencyWhole,
      isFuture,
      numberFormat,
      parseISO,
      plansFeatures,

      interval: 'year',

      reactivationLoading: false,
      cancelDowngradeLoading: false,

      showCancelModal: false,
      cancelingSubscription: null,
      cancelingState: cancelingStates.ready,

      showCanceledDowngradeModal: false,

      showReactivatedConfirm: false
    }
  },

  computed: {
    ...mapGetters({
      // New products system
      haveProductsLoaded: 'products/haveProductsLoaded',
      products: 'products/slotProducts',
      productsCountryCode: 'products/countryCode',
      productsTaxPercentage: 'products/taxPercentage',

      // Old plans system
      havePlansLoaded: 'plans/havePlansLoaded',
      legacyPlans: 'plans/tcPlans',

      currentSubscription: 'subscriptions/currentSubscription',
      activeSubscriptions: 'subscriptions/activeSubscriptions',
      canReactivateSubscription: 'subscriptions/canReactivateSubscription',
      hasActiveSubscription: 'subscriptions/hasActiveSubscription',
      hasPendingSubscription: 'subscriptions/hasPendingSubscription',
      haveSubscriptionsLoaded: 'subscriptions/haveSubscriptionsLoaded',
      inactiveSubscriptions: 'subscriptions/inactiveSubscriptions',

      attemptResultsLimit: 'organisations/attemptResultsLimit'
    }),

    /**
     * Return true if there is more than one unique interval
     *
     * @return {Boolean}
     */
    showPlansIntervalToggle() {
      if (!this.haveProductsLoaded) {
        return false
      }
      return [...new Set(this.products.map(product => product.recurringIntervals).flat())].length > 1
    },

    /**
     * @return {String}
     */
    preferredCountryCode() {
      return this.$route.query.country
    }
  },

  mounted() {
    this.loadSubscriptions()
  },

  methods: {
    /**
     * Load in subscriptions if missing
     */
    loadSubscriptions() {
      if (!this.haveSubscriptionsLoaded) {
        return this.$store.dispatch('subscriptions/getSubscriptions')
          .then(() => {
            this.loadProducts()
          })
          .catch(error => {
            this.cardState = this.cardStates.ERROR
            throw error
          })
      }
      this.loadProducts()
    },

    /**
     * Load in products if missing
     */
    loadProducts() {
      if (!this.haveProductsLoaded) {
        return this.$store.dispatch('products/getProducts', this.preferredCountryCode)
          .then(() => {
            this.productsReady()
          })
          .catch(error => {
            this.cardState = this.cardStates.ERROR
            throw error
          })
      }
      this.productsReady()
    },

    /**
     * Once products have loaded, we can set the current country code and mark
     * as productsReady
     */
    productsReady() {
      if (this.currentSubscription) {
        this.$store.dispatch('products/getProducts', this.preferredCountryCode, this.currentSubscription.currency)
          .then(() => {
            if (!this.countryCode) {
              this.countryCode = this.productsCountryCode
            }
            this.cardState = this.cardStates.READY
          })
          .catch(error => {
            this.cardState = this.cardStates.ERROR
            throw error
          })
      }

      if (!this.countryCode) {
        this.countryCode = this.productsCountryCode
      }

      this.cardState = this.cardStates.READY
    },

    /**
     * Place card back into loading state and then re-load the items with a new country
     */
    countryChange() {
      this.cardState = this.cardStates.LOADING

      this.$store.dispatch('products/getProducts', this.countryCode)
        .then(() => {
          this.cardState = this.cardStates.READY
        })
    },

    /**
     * @return {Array}
     */
    intervalPlans(plans) {
      return plans.filter(plan => {
        return plan.recurringInterval === this.interval
      })
    },

    /**
     * @param {Object} plan
     * @return {Object}
     */
    subscriptionToPlan(plan) {
      return this.activeSubscriptions.find(subscription => {
        return subscription.stripeId === plan.stripeId
      })
    },

    /**
     * @param {Object} plan
     * @return {Object}
     */
    pendingSubscriptionToPlan(plan) {
      return this.activeSubscriptions.find(subscription => {
        if (subscription.schedule) {
          return subscription.schedule.find(schedule => {
            return schedule.stripeId === plan.stripeId
          })
        }
      })
    },

    /**
     * @param {Object} plan
     * @return {Object}
     */
    canceledPlan(plan) {
      return this.inactiveSubscriptions.find(subscription => {
        return subscription.stripeId === plan.stripeId &&
          subscription.state === 'canceled'
      })
    },

    /**
     * @param {object} currencies
     * @return {string}
     */
    formatted(amount, currency) {
      if (!currency) {
        return amount / 100
      }
      return formatCurrencyWhole(amount / 100, currency)
    },

    /**
     * Returns the plan
     *
     * @param {string} slug
     * @return {Object}
     */
    plan(slug) {
      return this.plans.find(plan => plan.stripeId === slug)
    },

    /**
     * Returns confirm plan link
     *
     * @param {string} stripeId
     * @return {Object}
     */
    confirmPlanLink(stripeId) {
      const confirmLink = {
        name: 'confirm-plan',
        params: { plan: stripeId }
      }
      if (this.$route.query.code) {
        confirmLink.query = {
          code: this.$route.query.code
        }
      }
      return confirmLink
    },

    /**
     * Opens the cancel modal for a subscription
     *
     * @param {Object} subscription
     */
    openCancelPlanModal(subscription) {
      this.cancelingSubscription = subscription
      this.showCancelModal = true
    },

    /**
     * Cancel the subscription
     *
     * @param {Object} subscription
     */
    cancelAtPeriodEnd(subscription) {
      this.cancelingState = cancelingStates.loading
      this.$store.dispatch('subscriptions/cancelAtPeriodEnd', subscription.id)
        .then(() => {
          this.cancelingState = cancelingStates.canceled
        })
    },

    /**
     * Terminate the subscription
     *
     * @param {Object} subscription
     */
    terminateSubscription(subscription) {
      this.cancelingState = cancelingStates.loading
      this.$store.dispatch('subscriptions/terminateSubscription', subscription.uuid)
        .then(() => {
          this.cancelingState = cancelingStates.terminated
        })
    },

    /**
     * Returns the plan
     *
     * @param {Object} plan
     */
    reactivatePlan(plan) {
      this.reactivationLoading = true

      const inactiveSubscription = this.inactiveSubscriptions
        .find(subscription => {
          return subscription.stripeId === plan.stripeId &&
            subscription.state === 'canceled'
        })

      if (!inactiveSubscription) {
        this.reactivationLoading = false
        throw new Error('Cannot find subscription')
      }

      this.$store.dispatch('subscriptions/reactivateSubscription', inactiveSubscription.uuid)
        .then(() => {
          this.showReactivatedConfirm = true
          this.reactivationLoading = false
        })
    },

    cancelDowngrade(subscription) {
      this.cancelDowngradeLoading = true
      this.$store.dispatch('subscriptions/releaseSchedule', subscription.id)
        .then(() => {
          this.cancelDowngradeLoading = false

          if (this.$gtm) {
            this.$gtm.trackEvent({
              event: 'event',
              eventCategory: 'Subscription',
              eventAction: 'Canceled downgrade',
              eventLabel: subscription.stripeId
            })
          }

          this.showCanceledDowngradeModal = true
        })
    },

    setPlanInterval() {
      if (this.interval === 'year') {
        this.interval = 'month'
      } else {
        this.interval = 'year'
      }
    }
  }
}
</script>
