<template>
  <div>
    <SettingsNavigation />

    <div class="max-w-screen-xl mx-auto px-6">
      <PlanOptionsCard />
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import PlanOptionsCard from '@components/Plans/PlanOptionsCard'

export default {
  components: {
    SettingsNavigation,
    PlanOptionsCard
  }
}
</script>
